<template>
  <div class="fagui-details">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/policy' }"
        >政策文库</el-breadcrumb-item
      >
      <el-breadcrumb-item>政策文件</el-breadcrumb-item>
    </el-breadcrumb>
    <div >
      <div :class="['fg', { sticky: !flag }]" ref="fg" style="margin: 20px 0">
        <div
          v-if="flag"
          onmouseover="this.style.color = '#156ED0'"
          onmouseout="this.style.color = 'black'"
          @click="handleCopy"
        >
          {{ ZCData.title }}
        </div>
        <div
          v-else
          class="sticky-title"
          onmouseover="this.style.color = '#156ED0'"
          onmouseout="this.style.color = 'black'"
          @click="handleCopy"
        >
          {{ ZCData.title }}
        </div>
      </div>
      <div class="content">
        <div class="title-info">
          <div>
            <span>来源：{{ ZCData.fw_department }}</span>
            <span>发文日期：{{ ZCData.dispatch_date }}</span>
            <!-- <span>申报截止日期：{{ ZCData.declare_date }}</span> -->
          </div>
          <div>
            <span
              @click="setSubscribe"
              style="cursor: pointer"
              :style="
                subscribe
                  ? 'color:#CACACA'
                  : 'color:#3048f0;border:1px solid #156ED0;padding:2px;border-radius:5px'
              "
            >
              <!-- <img :src="subscribeImg" alt=""> -->
              {{ subscribe ? "已订阅" : "+ 订阅" }}
            </span>
            <el-dropdown style="position: relation">
              <span style="cursor: pointer"
                ><img
                  src="../../../../assets/pc/images/policy/share.png"
                  alt=""
                />分享</span
              >
              <el-dropdown-menu slot="dropdown" style="padding: 20px">
                <div class="share-content">
                  <!-- <div id="qrcode" ref="qrcode"></div>
                  <div class="flex" style="padding-top: 10px">
                    扫码进入小程序
                  </div> -->
                  <div >
                    <el-input
                      type="text"
                      class="input"
                      v-model="linkShare"
                      @click.native="handleShare"
                    ></el-input>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-row>
          <el-col :span="18">
            <el-radio-group v-model="radio1" @change="handleRadio">
              <el-radio-button label="/faguidetails/tongzhi"
                >政策原文</el-radio-button
              >
              <el-radio-button label="/faguidetails/wenji"
                >相关文件</el-radio-button
              >
            </el-radio-group>
          </el-col>
          <el-col :span="6" style="margin-top: 30px">
            <!-- <keep-alive> -->
            <el-button
              v-show="
                $store.state.policy.preview !== '1' ||
                $route.query.preview !== '1'
              "
              type="primary"
              style="float: right"
              @click="handleReturn"
              >返回上一级</el-button
            >
            <!-- </keep-alive> -->
          </el-col>
        </el-row>
        <router-view />
      </div>
      <permission
       v-if="dialogVisiblePermission"
        :dialogVisiblePermission="dialogVisiblePermission"
        @handleToggle="handleToggle"
        :typeDialog="typeDialog"
        @handlePermission="handlePermission"
      >
      </permission>
    </div>
    <el-dialog
      :visible.sync="subscribeVisible"
      width="300px">
      <div class="subscrib_dialog">
        <div>
          <img src="../../../../assets/pc/images/policy/subscribe 2.png" alt="">
        </div>
        <div>
          订阅成功
        </div>
        <div>
          点击 
          <span style="color: #1f88f9;cursor: pointer;" @click="goToRecord">智能推送 - 我的订阅</span>
           查看订阅记录
        </div>
        <el-button 
          type="primary"
          @click="subscribeVisible = false"
          style="width: 70%;">
          我知道了
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "../../../../network";
import { setInfoData, getMemberId } from "@/assets/public/utils/token";
import QRCode from "qrcodejs2";
import permission from "@/components/pc/permission.vue";
export default {
  components: {
    permission,
  },
  data() {
    return {
      flag: true,
      radio1: "/faguidetails/tongzhi",
      ZCData: {},
      subscribe: false,
      subscribeImg: require("../../../../assets/pc/images/policy/subscribe.png"),
      getIdFromRoute: "",
      linkShare: "",
      dialogVisiblePermission: false,
      typeDialog: "Vip",
      subscribeVisible: false
    };
  },
  created() {
    if (this.$route.query.id) {
      window.sessionStorage.setItem("fgid", this.$route.query.id);
      this.getIdFromRoute = this.$route.query.id;
    } else {
      if (window.sessionStorage.getItem("fgid"))
        this.getIdFromRoute = window.sessionStorage.getItem("fgid");
    }
    this.getPolicyById();
    this.getSubscribe();
    this.addZCHistory();
    setInfoData(request).then((res) => {
      // if (res === 'islogin') {
      //   this.linkShare = 'https://po-o.cn/#' + this.$route.fullPath;
      // } else {
      //   this.linkShare = 'https://po-o.cn/#' + '/login/loginbyverification';
      // }
      const txt =
        res === "islogin" ? this.$route.fullPath : "/login/loginbyverification";
      this.linkShare = process.env.VUE_APP_HTTP_URL + txt;
    });
  },
  mounted() {
    this.handleRoute();
    this.top = this.$refs.fg.offsetTop;
    addEventListener("scroll", this.onScroll, false);
    const qrcode = new QRCode("qrcode", {
      width: 150,
      height: 150, // 高度
      text: `${process.env.VUE_APP_Laws_Code}/redirect?id=${this.$route.query.id}`, // 二维码内容
      // correctLevel: QRCode.CorrectLevel.L,
      // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      // background: '#f0f'
      // foreground: '#ff0'
    });
  },
  beforeDestroy() {
    removeEventListener("scroll", this.onScroll, false);
  },
  computed: {
    getSubscribeImg() {
      const dingyue1img = require("../../../../assets/pc/images/policy/subscribe.png");
      const dingyue2img = require("../../../../assets/pc/images/policy/dingyue2.png");
      if (this.subscribe) return dingyue2img;
      return dingyue1img;
    },
  },
  methods: {
    async addZCHistory() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "post",
          url: "/pcp/policyDetail/addZCHistory",
          params: {
            zid: this.getIdFromRoute,
            memberId: getMemberId(),
          },
        });
        return res;
      }
    },
    onScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= this.top) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    handleRadio(data) {
      this.$router.replace({
        path: data,
        query: {
          id: this.getIdFromRoute,
        },
      });
    },
    handleRoute() {
      this.radio1 = this.$route.path;
    },
    async getPolicyById() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/getPolicyById",
        params: {
          id: this.getIdFromRoute,
        },
      });
      if (res.code !== 200) return;
      this.ZCData = res.data;
    },
    async getSubscribe() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/policyDetail/subscribe",
          params: {
            zid: this.getIdFromRoute,
            mid: getMemberId(),
          },
        });
        if (res.code !== 200)
          return this.$message.error("订阅状态信息获取失败");
        this.subscribe = res.data === "0";
        // this.getSubscribeImg()
        // this.dingyue1img
      }
    },
    async setSubscribe() {
      setInfoData(request).then(async (res) => {
        if (res === "islogin") {
          this.subscribe = !this.subscribe;
          this.subscribeImg = this.getSubscribeImg;
          const { data: res } = await request({
            method: "POST",
            url: "/pcp/policyDetail/setSubscribe",
            data: {
              zid: this.getIdFromRoute,
              type: this.subscribe ? 0 : 1,
              mId: getMemberId(),
            },
          });
          if (res.code !== 200) {
            this.subscribe = !this.subscribe;
            this.subscribeImg = this.getSubscribeImg;
            return this.$message.error("操作失败");
          }
          if (this.subscribe) {
            this.subscribeVisible = true;
          } else {
            this.$message.success("操作成功");
          }
          // this.$message.success("操作成功");
        } else {
          this.dialogVisiblePermission = !this.dialogVisiblePermission;
          this.typeDialog = "Login";
        }
      });
    },
    handleShare() {
      this.copyUrl(this.linkShare);
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission(type) {
      if (type === "Vip") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
      } else if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      const creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        showClose: true,
        message: "已复制好，可贴粘。",
        type: "success",
      });
    },
    handleCopy() {
      this.copyUrl(this.ZCData.title);
    },
    handleReturn() {
      localStorage.setItem("isDetailBack", true);
      this.$router.back();
      setTimeout(() => {
        if (this.$route.path.indexOf("faguidetails") !== -1)
          this.handleReturn();
      }, 100);
    },
    goToRecord () {
      this.$router.push({
        path: '/push/mysubscribe'
      });
    }
  },
  watch: {
    $route: "handleRoute",
  },
};
</script>

<style lang="less" scoped>
.fagui-details {
  padding: 43px 0;
  background-color: #fff;
  .el-breadcrumb {
    // padding: 0 55px;
  }
  .fg {
    padding: 40px 0px;
    display: flex;
    background-color: #fff;
    // height: 68px;
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 1;
      box-shadow: 0 2px 10px #ebecef;
    }
    div {
      font-size: 30px;
      align-self: center;
      cursor: pointer;
    }
    .sticky-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .content {
    // padding: 0 55px;
    .title-info {
      display: flex;
      font-size: 14px;
      color: #8d8d8d;
      padding-bottom: 18px;
      border-bottom: 2px solid #e5e5e5;
      div:nth-child(1) {
        flex: 1;
        span {
          margin-right: 30px;
        }
      }
      div:nth-child(2) {
        span {
          margin-left: 30px;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    .el-radio-group {
      margin: 30px 0 50px;
    }
  }
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #156ED0;
  border-color: #156ED0;
}
.subscrib_dialog{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 26px;
  .img{
    width: 36px;
    height: 36px;
  }
  & > div:first-child{
    margin-bottom: 20px;
  }
  .el-button{
    margin-top: 20px;
  }
}
</style>
